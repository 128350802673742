import React from "react";
import { RiShoppingBag4Line } from "react-icons/ri";
import classes from "./Sidebar.module.css";
import { Button } from "../../common";
import { ethereum } from "../../../images";
import { useState } from "react";
import Dropdown from "./Dropdown/Dropdown";
import clsx from "clsx";
const Sidebar = () => {
  const [activeTab, setActiveTab] = useState("BlockChain");
  const tabs = [
    {
      tab: "Blockchain",
      dropdownItems: [
        {
          label: "Networks",
        },
        { icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png", label: "BSC Network" },
      ],
    },
    {
      tab: "Status",
    },
    {
      tab: "Price",
    },
    {
      tab: "Category",
    },
    {
      tab: "Collections",
    },
  ];
  return (
    <div className={classes.wrapper}>
      {tabs.map((el, i) => (
        <div className={clsx(classes.tabContainer)} key={i}>
          {/* <div
            className={clsx(
              classes.tab,
              activeTab.toLowerCase() === el.tab.toLowerCase() &&
                classes.activeTab
            )}
            onClick={() => setActiveTab(el.tab)}
          >
            <h2 className={classes.tabName}>{el.tab}</h2>
            <Button surfaceMedium className={classes.button}>
              <RiShoppingBag4Line className={classes.shop} />
            </Button>
          </div> */}
          {activeTab.toLowerCase() === el.tab.toLowerCase() &&
            el.dropdownItems && <Dropdown dropdownItems={el.dropdownItems} />}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
