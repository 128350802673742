import React from "react";
import HeroSection from "../../components/NftDetails/HeroSection/HeroSection";
import CreateNft from "../../components/NftDetails/CreateNft/CreateNft";

const NftDetails = () => {
  return (
    <>
      <HeroSection />
      {/* <CreateNft /> */}
    </>
  );
};

export default NftDetails;
