import { Route, Routes } from "react-router-dom";

import NftDetails from "./pages/NftDetails/NftDetails";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import clsx from "clsx";
import { useTheme } from "./components/ThemeContext/ThemeContext";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import Home from "./pages/Home/Home";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// 1. Get projectId
const projectId = "d3873ed18b4ac26cf6f5dffdb2fe9cbd";

// 2. Set chains
const mainnet = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BSC",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://rpc.ankr.com/bsc",
};

// 3. Create a metadata object
const metadata = {
  name: "UNS Properties",
  description: "Purchase tokenized real estate assets",
  url: "https://properties.uns.technology", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  // enableEIP6963: true, // true by default
  // enableInjected: true, // true by default
  // enableCoinbase: true, // true by default
  // rpcUrl: '...', // used for the Coinbase SDK
  // defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function App() {
  const { isDarkTheme } = useTheme();
  return (
    <div className={clsx("mainPage", isDarkTheme ? "darkTheme" : "lightTheme")}>
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:name/:referrer_address" element={<NftDetails />} />
        <Route path="/:name" element={<NftDetails />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
