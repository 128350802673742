import React from "react";
import classes from "./Dropdown.module.css";
import { useState } from "react";
import clsx from "clsx";
const Dropdown = ({ dropdownItems }) => {
  const [activeItem, setActiveItem] = useState("All");
  return (
    <div className={classes.wrapper}>
      {dropdownItems.map((item, i) => (
        <div
          className={clsx(
            classes.item,
            activeItem.toLowerCase() === item.label.toLowerCase() &&
              classes.activeItem
          )}
          key={i}
          onClick={() => setActiveItem(item.label)}
        >
          {item.icon && (
            <img src={item.icon} alt="#" className={classes.icon} />
          )}
          <p className={classes.label}>{item.label}</p>
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
