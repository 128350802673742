import React, { useEffect, useRef } from "react";
import classes from "./Nfts.module.css";
import { nft1, nft2, nft3 } from "../../../images";
import { Heading, Text } from "../../common";
import clsx from "clsx";
import { Link } from "react-router-dom";

const Nfts = ({ nfts }) => {
  return (
    <div className={clsx(classes.wrapper, "overflow")}>
      {nfts.map((nft, i) => (
        <Link
          onClick={() => {
            if (!nft.link_to) {
              alert("Yet to be launched!");
            }
            nft.link_to && window.open(nft.link_to, "_blank");
          }}
          className={classes.nft}
          key={i}
        >
          <div className={classes.header}>
            <Text xs primary className={classes.name}>
              {nft.name}
            </Text>{" "}
            <Text xs primary className={classes.price}>
              {nft.price}
            </Text>
          </div>
          <div className={classes.imgContainer}>
            <img height={300} src={nft.img} alt="#" className={classes.img} />
            <div className={clsx(classes.descriptionContainer, "overflow")}>
              <div className={classes.detailsBox}>
                <Heading base primary className={classes.details}>
                  Description:
                </Heading>
                <Text base900 primary secondary className={classes.details}>
                  {nft.description}
                </Text>{" "}
              </div>
              {nft.propertyDetails && (
                <div className={classes.detailsBox}>
                  <Heading base primary className={classes.details}>
                    Property Details:
                  </Heading>
                  <Text base900 primary secondary className={classes.details}>
                    {nft.propertyDetails}
                  </Text>{" "}
                </div>
              )}
              {nft.location && (
                <div className={classes.detailsBox}>
                  <Heading base primary className={classes.details}>
                    Location:
                  </Heading>
                  <Text base900 primary secondary className={classes.details}>
                    {nft.location}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Nfts;
